import { apiConfig } from "../config/Config.js";
import { apiEndPoint } from "../constant/ApiConstant.js";
import { ApiService } from "../service/ApiService.js";
import UtilDateTime from "../util/UtilDateTime.js";
import { trimValue } from "../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_GROUP_ID,
  SC_LOGIN_USER_ID,
} from "../constant/StorageConstant.js";
import UserSessionController from "./UserSession/UserSessionController.js";

let SuperAdminDashboardCtrlr = {

// ---------------------------------- Super Admin Dashboard Summary api -------------------------------------------------- //

    async getV1SuperAdminDashboardSummary( startDateTime, endDateTime, filter) {
       
        var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);        
        var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);        
        var queryFields     = { durationType: filter,};
    
        if (companyId.length > 0 && divisionId.length > 0) {

          var reqParams = {
            companyId       : companyId,
            groupId         : groupId,
            divisionId      : divisionId,            
            queryType       : "QV1_SUPER_ADMIN_DASHBOARD_SUMMARY",
            startDateTime   : startDateTime,
            endDateTime     : endDateTime,
            queryFields : JSON.stringify(queryFields),
          };
              
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.QUERY,
            1,
            reqParams
          );

        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed","code":"412", "data": []}';
        }
      },

// --------------------------------------------------------------------------------------------- //

// ---------------------------------- Admin Dashbord Schedule based user periodical summary api -------------------------------------------------- //

async getSuperAdminV1UserList (indexStart,searchText,userListType){
   
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);    
  var queryFields     = {searchType: "WILD_CARD_SEARCH",searchText:searchText,userListType:userListType,}


  if (companyId.length > 0) {

    var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,          
        sortBy          : "createdAt",       
        offset          : indexStart,
        limit           : 5,
        queryType       : "QV1_USER_LIST",
        queryFields     : JSON.stringify(queryFields),
        
    };

    return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
    );

} else {

    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
}

},

// --------------------------------------------------------------------------------------------- //

//---------------------------------------App Exception API Controller -----------------------------//

async getSuperAdminV1ExceptionUserList (indexStart,searchText,userListType,deviceFilter,exceptionFilter2){
   
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);    
  var queryFields     = {searchType: "WILD_CARD_SEARCH",searchText:searchText,userListType:userListType,deviceType:deviceFilter,exceptionCategory:exceptionFilter2}


  if (companyId.length > 0) {

    var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,          
        sortBy          : "createdAt",       
        offset          : indexStart,
        limit           : 5,
        queryType       : "QV1_APP_EXCEPTION_LIST",
        queryFields     : JSON.stringify(queryFields),
        
    };

    return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
    );

} else {

    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
}

},

//------------------------------------------------------------------------------------------//

//---------------------------------------App Permission API Controller -----------------------------//

async getSuperAdminV1PermissionUserList (indexStart,searchText,userListType,deviceFilter,exceptionFilter2){
   
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);    
  var queryFields     = {searchType: "WILD_CARD_SEARCH",searchText:searchText,userListType:userListType,deviceType:deviceFilter,exceptionCategory:exceptionFilter2}


  if (companyId.length > 0) {

    var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,          
        sortBy          : "createdAt",       
        offset          : indexStart,
        limit           : 5,
        queryType       : "QV1_APP_PERMISSION_LIST",
        queryFields     : JSON.stringify(queryFields),
        
    };

    return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
    );

} else {

    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
}

},

//------------------------------------------------------------------------------------------//

};

export default SuperAdminDashboardCtrlr;
